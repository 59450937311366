var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"d-content-page",class:{
		'd-content-page--no-footer-gap': !_vm.hasFooterGap,
	}},[_c('PageHeader',{attrs:{"intro":_vm.intro,"icon-url":_vm.iconUrl,"details":_vm.details,"layout":_vm.template === 'publication' ? 'publication' : 'default'}}),_vm._v(" "),_c('LongReadController',{key:_vm.$route.path,attrs:{"id":"long-read-controller"},scopedSlots:_vm._u([{key:"default",fn:function(longReadController){return [(_vm.showToc || _vm.links)?_c('PageProgressBar',{class:{
				'<1024:hidden': !_vm.showToc,
			}},[(_vm.showToc)?_c('PageProgressBarToc',_vm._b({attrs:{"placeholder":_vm.toc.title,"toc-to-top":_vm.toc.toTheTop}},'PageProgressBarToc',longReadController,false)):_vm._e(),_vm._v(" "),(_vm.links)?_c('LinkDropdown',{staticClass:"ml-auto <1024:hidden min-w-280 mr-layout-margin",attrs:{"links":_vm.links.items,"label":_vm.links.label,"description":_vm.links.description}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.links)?_c('LinkDropdown',{staticClass:"\n\t\t\t\tw-fit\n\t\t\t\tmin-w-280\n\t\t\t\tmx-auto\n\t\t\t\tmt-16\n\t\t\t\t<768:w-full\n\t\t\t\t>=1024:hidden\n\t\t\t\tpx-layout-margin\n\t\t\t",attrs:{"links":_vm.links.items,"label":_vm.links.label,"description":_vm.links.description,"all-red":""}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\tgrid grid-cols-12\n\t\t\t\titems-start\n\t\t\t\tgap-y-2xl/v\n\t\t\t\tpx-layout-margin\n\t\t\t\tmt-2xl/v\n\t\t\t"},[_c('aside',{staticClass:"col-span-full >=1024:col-span-3"},[(_vm.contactPersons && _vm.contactPersons.length)?_c('AsideContactPersons',{attrs:{"contact-persons":_vm.contactPersons}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\tcol-span-full\n\t\t\t\t\t>=1024:col-start-5 >=1024:col-span-8\n\t\t\t\t\tgrid grid-cols-12\n\t\t\t\t\t>=1024:grid-cols-8\n\t\t\t\t\titems-start\n\t\t\t\t\tspace-y-2xl/v\n\t\t\t\t"},[(_vm.intro && _vm.intro.teaser)?_c('p',{staticClass:"\n\t\t\t\t\t\tcol-span-full\n\t\t\t\t\t\tfont-light\n\t\t\t\t\t\ttext-sub-teaser text-red\n\t\t\t\t\t",domProps:{"textContent":_vm._s(_vm.intro.teaser)}}):_vm._e(),_vm._v(" "),(_vm.blocks && _vm.blocks.length)?_c('BlockList',{staticClass:"col-span-full",attrs:{"block-list":_vm.blocks}}):_vm._e(),_vm._v(" "),(_vm.aboutSection)?_c('section',{staticClass:"\n\t\t\t\t\t\td-content-page__about\n\t\t\t\t\t\tisolate\n\t\t\t\t\t\trelative\n\t\t\t\t\t\tw-12/12col\n\t\t\t\t\t\tjustify-self-end\n\t\t\t\t\t\tcol-span-full\n\t\t\t\t\t\t>=1024:col-start-5 >=1024:col-span-8\n\t\t\t\t\t\tpb-sm/v\n\t\t\t\t\t",class:{
						'pt-0 mt-0': _vm.lastBlockIsGrayBlock,
						'pt-sm/v': !_vm.lastBlockIsGrayBlock,
					}},_vm._l((_vm.aboutSection),function(group,index){return _c('div',{key:index,staticClass:"py-lg/v grid grid-cols-12 gap-y-xs/v",class:{
							'border-t border-gray-medium':
								_vm.lastBlockIsGrayBlock || index,
						},attrs:{"id":("d-content-page__about-" + _vm._uid + "-" + index)}},[(group.title)?_c('h2',{staticClass:"\n\t\t\t\t\t\t\t\ttext-search-heading text-gray-darker\n\t\t\t\t\t\t\t\tcol-span-full\n\t\t\t\t\t\t\t\t>=1024:col-span-3\n\t\t\t\t\t\t\t\t>=1400:col-span-2\n\t\t\t\t\t\t\t",domProps:{"textContent":_vm._s(group.title)}}):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"\n\t\t\t\t\t\t\t\tcol-span-full\n\t\t\t\t\t\t\t\t>=1024:col-span-8 >=1024:col-start-5\n\t\t\t\t\t\t\t\tgrid grid-cols-8\n\t\t\t\t\t\t\t\tgap-y-2xs/v\n\t\t\t\t\t\t\t\ttext-body\n\t\t\t\t\t\t\t"},[_vm._l((group.items),function(item,itemIndex){return _c('li',{key:itemIndex,staticClass:"\n\t\t\t\t\t\t\t\t\tcol-span-full\n\t\t\t\t\t\t\t\t\tgrid grid-cols-1\n\t\t\t\t\t\t\t\t\tcontent-start\n\t\t\t\t\t\t\t\t\tgap-y-4\n\t\t\t\t\t\t\t\t",class:{
									'>=1024:col-span-8':
										group.items.length === 1,
									'>=1024:col-span-3 >=1024:pr-sm/h':
										group.items.length > 1 &&
										(group.items.length - itemIndex) %
											2 ===
											0,
									'>=1024:col-span-5':
										group.items.length > 1 &&
										(group.items.length - itemIndex) %
											2 ===
											1,
								}},[(item.label)?_c('h3',{staticClass:"font-medium",domProps:{"textContent":_vm._s(item.label)}}):_vm._e(),_vm._v(" "),(item.value)?_c('div',{staticClass:"\n\t\t\t\t\t\t\t\t\t\td-content-page__people-list\n\t\t\t\t\t\t\t\t\t\tfont-light\n\t\t\t\t\t\t\t\t\t"},[_vm._l((item.value),function(value,nameIndex){return [(value.url)?_c('span',{key:nameIndex + '-link'},[_c('NuxtLinkExt',{staticClass:"text-red underline",attrs:{"to":value.url},domProps:{"textContent":_vm._s(
													[
														value.name,
														value.comment &&
															("(" + (value.comment) + ")") ]
														.filter(Boolean)
														.join(' ')
												)}})],1):_c('span',{key:nameIndex,domProps:{"textContent":_vm._s(
												[
													value.name,
													value.comment &&
														("(" + (value.comment) + ")") ]
													.filter(Boolean)
													.join(' ')
											)}})]})],2):_vm._e()])}),_vm._v(" "),_vm._l((group.fields),function(field,fieldIndex){return _c('li',{key:fieldIndex,staticClass:"\n\t\t\t\t\t\t\t\t\tcol-span-full\n\t\t\t\t\t\t\t\t\tgrid grid-cols-1\n\t\t\t\t\t\t\t\t\tcontent-start\n\t\t\t\t\t\t\t\t\tgap-y-4\n\t\t\t\t\t\t\t\t",class:{
									'>=1024:col-span-8':
										group.fields.length === 1,
									'>=1024:col-span-3 >=1024:pr-sm/h':
										group.fields.length > 1 &&
										(group.fields.length - fieldIndex) %
											2 ===
											0,
									'>=1024:col-span-5':
										group.fields.length > 1 &&
										(group.fields.length - fieldIndex) %
											2 ===
											1,
								}},[(field.label)?_c('h3',{staticClass:"font-medium",domProps:{"textContent":_vm._s(field.label)}}):_vm._e(),_vm._v(" "),(field.value)?_c('div',{staticClass:"font-light",domProps:{"textContent":_vm._s(field.value)}}):_vm._e()])})],2)])}),0):_vm._e()],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }